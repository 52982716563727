<template>
  <div>
    <!-- 搜索 -->
    <div class="search-box">
    <el-form :inline="true" :model="queryForm" class="demo-form-inline">
      <el-form-item label="栏目名称">
        <el-input v-model="queryForm.channelName" placeholder="请输入栏目名称" size="small" @input="queryChannel" clearable @clear="queryChannel"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryChannel" size="small">查询</el-button>
        <el-button v-show="hasAddPermision" icon="el-icon-plus" @click="addInfo" size="small">新增栏目</el-button>
      </el-form-item>
    </el-form>
  </div>

  <!-- 列表  -->
  <div class="data-box">
    <el-table v-loading="loading"
              border
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              row-key="id"
              default-expand-all
              :data="tableData" style="width: 100%;">
      <el-table-column prop="name" label="栏目名称">
        <template #default="scope">
          {{scope.row.id}}-{{scope.row.name}}
        </template>
      </el-table-column>
      <el-table-column prop="seq" label="显示排序"/>
      <el-table-column label="操作" v-if="hasEditPermision || hasDeletePermision">
        <template #default="scope">
          <el-button v-show="hasEditPermision" size="mini" @click="editInfo(scope.$index, scope.row)">编辑</el-button>
          <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              :title="'确定删除内容吗?'"
              @confirm="delInfo(scope.$index, scope.row)">
            <template #reference>
              <el-button v-show="hasDeletePermision" icon="el-icon-delete" size="mini" type="danger">删除</el-button>
            </template>
            </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="page-box">
    共 {{total}} 条
  </div>

  <!-- 新增、修改 -->
  <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-model="dialogVisible"
      :title="dialogTitle"
      width="1158px"
      top="2vh"
      @close="handleClose"
      :fullscreen="fullscreen"
      destroy-on-close
  >
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
        class="demo-ruleForm"
    >
      <el-form-item label="上级栏目" prop="parentChannelName" class="label-color">
        <el-select ref="rlueFormTreeSelect" v-model="ruleForm.parentChannelName"  placeholder="请选择上级栏目(留空则为一级栏目)" fit-input-width="true" style="width:100%" clearable>
          <el-option style="height: auto;padding: 0;">
            <el-tree expand-on-click-node="false" default-expand-all ref="tree" highlight-current="true" :data="dataTree" node-key="id" :props="{class:customNodeClass}"
                     @node-click="handleNodeClick"/>
          </el-option>
        </el-select>
      </el-form-item>
      <el-row :gutter="10">
        <el-col :span="13">
          <el-form-item label="栏目名称" prop="name" class="label-color">
            <el-input v-model="ruleForm.name" placeholder="请输入栏目名称" clearable ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="显示排序" prop="seq" class="label-color">
            <el-input-number v-model="ruleForm.seq" :min="1" style="width: 100%"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="是否单页"  class="label-color">
            <el-switch
              class="el-switch-my"
              active-color="#13ce66"
              v-model="ruleForm.single"
              :active-value="true"
              :inactive-value="false"
            >
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="栏目图标" class="label-color">
        <el-row :span="24">
          <div>
            <el-upload
                class="avatar-uploader"
                :action="thumbnailAction"
                :on-change="uploadImage"
                :show-file-list="false"
                :before-upload="beforeThumbnailUpload">
              <img v-if="ruleForm.icon!='' && ruleForm.icon!=undefined" :src="ruleForm.icon" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div style="margin-left: 5px;">
            <div style="margin-top:20px;"><el-button  icon="el-icon-zoom-in" @click="ruleForm.icon!=''?dialogThumbnail = true:''" circle></el-button></div>
            <div style="margin-top:20px;"><el-button icon="el-icon-delete" @click="ruleForm.icon =''" circle></el-button></div>
          </div>
        </el-row>
<!--        <el-link :underline="false" type="primary">{{picTip}}</el-link>-->
      </el-form-item>

      <el-form-item label="栏目内容" class="label-color">
        <editor-bar
          style="width: 100%; float: left;"
          v-model="ruleForm.content"
          :stickyHeight="210"
          :content="ruleForm.content"
          :isClear="isClear"
          @change="changeContent"
        ></editor-bar>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="fullscreen = !fullscreen" type="success">{{fullscreen?'取消最大化':'最大化'}}</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveInfo" v-if="isSave==true">保存</el-button>
        <el-button type="primary" @click="updateInfo" v-if="isSave==false">修改</el-button>
      </span>
    </template>
  </el-dialog>

  </div>

  <el-dialog title="图片预览" width="1000px"  v-model="dialogThumbnail" :show-close="false" append-to-body>
    <div class="preview">
      <img :src="ruleForm.icon" alt="">
    </div>
  </el-dialog>
</template>

<script>
  import channelApi from '@/api/info/channel'
  import {handleTree} from '@/utils/treeUtil'
  import EditorBar from './Editor.vue';
  import replaceEditorImgURL from '@/utils/replaceEditorImgURL'
  import config from "@/api/config";
  import imageUtil from '@/utils/imageUtil'
export default {
  components: { EditorBar },
  data() {
    return {
      fullscreen:false,
      hasAddPermision:false,
      hasEditPermision:false,
      hasDeletePermision:false,
      isClear:false,
      queryForm:{
        channelName:'',
      },
      loading:false,
      tableData:[],
      total:0,
      dialogVisible:false,
      dialogTitle:'',
      ruleForm:{
        id:'',
        parentId:'',
        parentChannelName:'',
        name:'',
        seq:'',
        icon:'',
        content:''
      },
      rules:{
        name:{
          required: true,
            message: '请输入栏目名称',
            trigger: 'blur',
        },
        seq: [
          {
            required: true,
            message: '请输入显示排序',
            trigger: 'blur',
          },
        ],
      },
      dataTree:[],
      isSave: false,
      thumbnailAction:'',  //上传缩略图服务地址
      dialogThumbnail: false,
    }
  },
  mounted(){
     this.$nextTick(()=> {
      // 获取设置按钮权限
      this.hasAddPermision = this.$menuUtil.hasMenu('/info/channelManage:add');
      this.hasEditPermision = this.$menuUtil.hasMenu('/info/channelManage:edit');
      this.hasDeletePermision = this.$menuUtil.hasMenu('/info/channelManage:delete');
    })
    this.queryChannelTree();
    this.queryChannel();
  },
  methods: {
    uploadImage(file, fileList){
      imageUtil.getBase64(file.raw).then(res =>{
        this.ruleForm.icon = res;
      })
    },

    beforeThumbnailUpload(file) {
      let types = ['image/jpeg', 'image/jpg', 'image/png'];
      const isJPG = types.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPEG、JPG、PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    changeContent(val){
      this.ruleForm.content = val;
    },
    // 保存新增栏目
    saveInfo(){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if(this.ruleForm.content.indexOf(config.server) != -1){
            this.ruleForm.content = this.ruleForm.content.replaceAll(config.server, '')
          }
          this.ruleForm.icon = (this.ruleForm.icon != '' && this.ruleForm.icon != undefined) ?this.ruleForm.icon.replaceAll(config.server, ''):'';
          channelApi.addChannel(this.ruleForm).then(res => {
            if(res.data.code == 1){
              this.$notify({
                title: res.data.msg,
                type: 'success',
                offset:40,
                duration: 2000
              });
                 // 关闭窗口
                this.dialogVisible=false;

                //刷新
                this.queryChannel();
                this.queryChannelTree();
            }else{
                  this.$notify.info({
                    title: res.data.msg,
                    type: 'warning',
                    offset:40,
                    duration: 3000
                  });
                }
          })
        }
      })
      
    },
    // 删除栏目
    delInfo(index, row){
      channelApi.delChannel(row.id).then(res => {
        if(res.data.code == 1){
          this.$notify({
            title: res.data.msg,
            type: 'success',
            offset:40,
            duration: 2000
          });
          //刷新
          this.queryChannel();
          this.queryChannelTree();
        } else {
          this.$notify({
            title: res.data.msg,
            type: 'warning',
            offset:40,
            duration: 3000
          });
        }
      })
        

    },
    // 修改栏目
    updateInfo(){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if(this.ruleForm.content.indexOf(config.server) != -1){
            this.ruleForm.content = this.ruleForm.content.replaceAll(config.server, '')
          }
          this.ruleForm.icon = (this.ruleForm.icon != '' && this.ruleForm.icon != undefined) ?this.ruleForm.icon.replaceAll(config.server, ''):'';
          channelApi.editChannel(this.ruleForm).then(res => {
            if(res.data.code == 1){
              this.$notify({
                title: res.data.msg,
                type: 'success',
                offset:40,
                duration: 2000
              });
                 // 关闭窗口
                this.dialogVisible=false;

                //刷新
                this.queryChannel();
                this.queryChannelTree();
            }else{
                  this.$notify.info({
                    title: res.data.msg,
                    type: 'warning',
                    offset:40,
                    duration: 3000
                  });
                }
          })
        }
      })

    },

    //新增栏目 dialog
    addInfo(){
      this.isSave = true;
      this.dialogTitle='新增栏目';
      this.dialogVisible = true;

      this.ruleForm={};
      this.ruleForm.parentId='';
      this.ruleForm.seq=1;
    },
    //新增、编辑父级栏目选择
    handleNodeClick(data) {
      this.ruleForm.parentChannelName = data.label;
      this.ruleForm.parentId=data.id;
      this.$refs.rlueFormTreeSelect.blur();// 让select失去焦点，收起下拉
    },
    customNodeClass(data, node) {
      if (data.isPenultimate) {
        return 'is-penultimate'
      }
      return ''
    },
    //编辑栏目
    editInfo(index, row){
      this.isSave = false;
      this.dialogTitle='编辑栏目';
      this.dialogVisible = true;
      this.ruleForm.parentId = row.parentId;
      this.ruleForm.parentChannelName = row.parentChannelName;
      this.ruleForm.id = row.id;
      this.ruleForm.name = row.name;
      this.ruleForm.seq = row.seq;
      this.ruleForm.icon = row.icon;
      this.ruleForm.single = row.single;
      if(row.content!=null) {
        this.ruleForm.content = replaceEditorImgURL.toReplaceEditorImgURL(row.content);
      }
      this.ruleForm.icon = (row.icon != '' && row.icon != null ) ? config.server + row.icon : '';

      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(row.parentId);
      });
    },
    //查询栏目- tree
    queryChannelTree(){
      channelApi.queryChannel({channelName:''}).then(res => {
        this.dataTree = handleTree(res.data.data, 'name');
      })
    },
    // 查询栏目列表
    queryChannel(){
      channelApi.queryChannel(this.queryForm).then(res => {
        this.tableData = handleTree(res.data.data);
        console.log(res.data.data)
        this.total=res.data.data.length;
      })
    },
    // 弹窗关闭事件
    handleClose(){
        this.ruleForm={};
        this.$refs.tree.setCheckedKeys([]);// 清空选中
      }
  },
}
</script>

<style scoped>
/* 上传 */
/deep/.avatar-uploader .el-upload {
  width: 210px;
  height: 120px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #000000;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 130px;
  text-align: center;
}
.avatar {
  height: 130px;
  /* display: block; */
  object-fit: cover;
}
.preview{
  overflow: hidden;
}
.preview img{
  min-width: 100px;
  background-color: #000000;
  max-width: 1000px;
}
</style>